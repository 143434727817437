import DropDown from '../../../../../components/core/buttons/DropDown';
import CircledPolicyTypeIcon from '../../../../../components/core/CircledPolicyTypeIcon';
import Container from '../../../../../components/core/Container';
import FlexBox from '../../../../../components/core/FlexBox';
import { ArrowDownIcon } from '../../../../../components/core/icons';
import Paragraph from '../../../../../components/core/Paragraph';
import Text from '../../../../../components/core/Text';
import PriorPolicyEditor from '../../../../../components/PriorPolicyEditor';
import PriorPolicyWithHomeForm from '../../../../../components/PriorPolicyEditor/PriorPolicyWithHomeForm';
import PriorPolicyWithVehiclesForm from '../../../../../components/PriorPolicyEditor/PriorPolicyWithVehiclesForm';
import { useGuidedSellingExperienceContext } from '../../../../../contexts/GuidedSellingExperienceContext';
import { IOpportunity, IPolicy } from '../../../../../interfaces';
import { opportunityDescription } from '../../../../../interfaces/IOpportunity';
import {
  findPolicyTypeLabel,
  InsuranceCategory,
  isInsurableInterestRealProperty,
  isRequirementEssential,
  policyTypesForCategory
} from '../../../../../interfaces/IPolicyType';
import usePersonOpportunities from '../../../../../queries/people/person_opportunities/usePersonOpportunities';
import usePersonAllGroupedPolicies from '../../../../../queries/people/person_policies/usePersonAllGroupedPolicies';
import usePersonLoans from '../../../../../queries/people/usePersonLoans';
import colors from '../../../../../theme/colors';
import { spacings } from '../../../../../theme/variables';
import { dateFormatter, relativeTimeFormatter } from '../../../../../utils/formatter';
import PoliciesTableSkeleton from '../../../_components/PoliciesTableSkeleton';
import { secondaryCoreBundleOpportunity } from '../../../_helpers';
import { usePriorPolicyFormUtils } from '../../../_hooks/usePriorPolicyFormUtils';
import PolicyGroupTable, { HeadingProps } from '../../../tabs/PoliciesTab/_components/PolicyGroupTable';
import MyCoverageInfo from '../_components/MyCoverageInfo';
import OpportunityWithoutPolicy from '../_components/OpportunityWithoutPolicy';

const isSimilarEntity = ({
  opportunity: { assets: oppAssets, policy_type: oppPolicyType },
  policy: { assets: policyAssets, policy_type: policyPolicyType }
}: {
  opportunity: IOpportunity;
  policy: IPolicy;
}) => {
  if (!oppAssets || !policyAssets) {
    return false;
  }

  const oppAssetsGids = oppAssets
    .map(({ gid }) => gid)
    .sort()
    .join('');
  const policyAssetsGids = policyAssets
    .map(({ gid }) => gid)
    .sort()
    .join('');

  return oppAssetsGids === policyAssetsGids && oppPolicyType === policyPolicyType;
};

const RenderHeading = ({ policy, activePolicy, policyNumber }: HeadingProps) => {
  const isCoverageLookupAvailable =
    isInsurableInterestRealProperty(policy.policy_type) && isRequirementEssential(policy.policy_type);

  const expiration_date = activePolicy?.expiration_date;

  return (
    <FlexBox columnDirection gap={spacings.px8}>
      <FlexBox alignItemsCenter justifySpaceBetween gap={spacings.px8}>
        <FlexBox alignItemsCenter gap={spacings.px8}>
          <CircledPolicyTypeIcon policyType={policy.policy_type} />
          <Paragraph bold>{findPolicyTypeLabel(policy.policy_type) || '-'}</Paragraph>
        </FlexBox>
        {isCoverageLookupAvailable && <MyCoverageInfo />}
      </FlexBox>
      <FlexBox justifySpaceBetween gap={spacings.px8}>
        {(policy.assets || []).length > 0 && (
          <Paragraph color={colors.grey60} className="fs-mask">
            {opportunityDescription(policy)}
          </Paragraph>
        )}
        {(policy.assets || []).length === 0 && expiration_date && (
          <Text type="small">
            expiration {dateFormatter(expiration_date)} ({relativeTimeFormatter(expiration_date)})
          </Text>
        )}
        <Paragraph type="tiny" color={colors.grey80} className={activePolicy?.policy_number ? 'fs-mask' : undefined}>
          {policyNumber}
        </Paragraph>
      </FlexBox>
      {(policy.assets || []).length > 0 && expiration_date && (
        <Text type="small">
          expiration {dateFormatter(expiration_date)} ({relativeTimeFormatter(expiration_date)})
        </Text>
      )}
    </FlexBox>
  );
};

type IHeadSection = Pick<
  ReturnType<typeof usePriorPolicyFormUtils>,
  'onSelected' | 'policiesOptions' | 'isDataEditingForbidden'
> & { isLifeLead: boolean | undefined };

const CurrentPolicies = ({ renderHeadSection }: { renderHeadSection?: (args: IHeadSection) => React.ReactNode }) => {
  const { person, personGid, leadGid, candidateGid, isLifeLead, insuranceCategory } =
    useGuidedSellingExperienceContext();

  const { data: opportunities } = usePersonOpportunities({
    personGid,
    leadGid,
    candidateGid
  });
  const primaryOpportunity = opportunities?.find(({ primary }) => primary);
  const secondaryOpportunity = secondaryCoreBundleOpportunity({ opportunities });

  const primaryOpportunityAsset = primaryOpportunity?.assets?.[0];
  const { data: loans = [] } = usePersonLoans({ personGid, assetGid: primaryOpportunityAsset?.gid });
  const primaryOpportunityLoan = loans[0];

  const { data: personPoliciesGroups, isSuccess } = usePersonAllGroupedPolicies({
    personGid: personGid!,
    filters: {
      policy_types: policyTypesForCategory({ insuranceCategory: insuranceCategory || InsuranceCategory.PersonalLines }),
      group_by_policy_type: true,
      show_predicted_policies: true,
      deduplicate_by_expiration_date: true
    }
  });

  const opportunitiesGroup = [
    personPoliciesGroups?.find(g => {
      if (!primaryOpportunity || !g.group[0]) {
        return false;
      }

      return isSimilarEntity({
        opportunity: primaryOpportunity,
        policy: g.group[0]
      });
    }),
    personPoliciesGroups?.find(g => {
      if (!secondaryOpportunity || !g.group[0]) {
        return false;
      }

      return isSimilarEntity({
        opportunity: secondaryOpportunity,
        policy: g.group[0]
      });
    })
  ];

  const orderedGroups = [
    ...opportunitiesGroup,
    ...(personPoliciesGroups || []).filter(g => !opportunitiesGroup.includes(g))
  ];

  const {
    selectedPolicyOptions,
    addHomeFlow,
    addVehiclesFlow,
    priorPolicyEditorCommonProps,
    policiesOptions,
    onSelected,
    isDataEditingForbidden
  } = usePriorPolicyFormUtils({ opportunities, uiSource: 'summary' });

  return (
    <FlexBox columnDirection>
      <FlexBox alignItemsCenter gap={spacings.px8}>
        {renderHeadSection ? (
          renderHeadSection({ policiesOptions, onSelected, isDataEditingForbidden, isLifeLead })
        ) : (
          <>
            <Paragraph type="large" bold>
              Current policies
            </Paragraph>
            <DropDown options={policiesOptions} onSelected={onSelected} iconChildren disabled={isDataEditingForbidden}>
              <Paragraph type="small" color={isDataEditingForbidden ? colors.grey60 : colors.azure50} bold>
                {isLifeLead ? 'Add policy' : 'Add policy and asset'} <ArrowDownIcon />
              </Paragraph>
            </DropDown>
          </>
        )}
        {personGid && selectedPolicyOptions && (
          <>
            {!addVehiclesFlow && !addHomeFlow && (
              <PriorPolicyEditor {...priorPolicyEditorCommonProps} personGid={personGid} />
            )}
            {addHomeFlow && (
              <PriorPolicyWithHomeForm {...priorPolicyEditorCommonProps} personGid={personGid} leadGid={leadGid} />
            )}
            {addVehiclesFlow && person && (
              <PriorPolicyWithVehiclesForm {...priorPolicyEditorCommonProps} person={person} leadGid={leadGid} />
            )}
          </>
        )}
      </FlexBox>
      <FlexBox mt={spacings.px16} columnDirection gap={spacings.px16}>
        {isSuccess &&
          orderedGroups.map((group, index) => {
            if (!group) {
              const opportunity = index === 0 ? primaryOpportunity : secondaryOpportunity;
              if (!opportunity) {
                return null;
              }
              const policyOption =
                policiesOptions.find(({ value }) => value === opportunity.id) ||
                policiesOptions.find(({ value }) => value === opportunity.policy_type);

              return (
                <OpportunityWithoutPolicy
                  key={opportunity.id}
                  opportunity={opportunity}
                  loan={index === 0 ? primaryOpportunityLoan : undefined}
                  isDataEditingForbidden={isDataEditingForbidden || !policyOption}
                  onAddPolicy={() => onSelected(policyOption!)}
                />
              );
            }

            return (
              <Container border roundBorder key={group.group_by.join(',')}>
                <Container p={spacings.px8}>
                  <PolicyGroupTable policyGroup={group} collapseWidth={-1} renderHeading={RenderHeading} />
                </Container>
              </Container>
            );
          })}
        {!isSuccess && <PoliciesTableSkeleton />}
      </FlexBox>
    </FlexBox>
  );
};

export default CurrentPolicies;
