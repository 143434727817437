/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Link } from '../../../../components/common';
import ButtonWithoutBorder from '../../../../components/core/buttons/ButtonWithoutBorder';
import Container from '../../../../components/core/Container';
import Copy from '../../../../components/core/Copy';
import FlexBox from '../../../../components/core/FlexBox';
import { OpenInNewIcon } from '../../../../components/core/icons';
import Paragraph from '../../../../components/core/Paragraph';
import { dispositionLabel } from '../../../../components/DispositionsModals/dispositionsHelper';
import { Translations } from '../../../../constants';
import featureFlags from '../../../../constants/featureFlags';
import { useCallState } from '../../../../contexts/CallContext';
import { useGuidedSellingExperienceContext } from '../../../../contexts/GuidedSellingExperienceContext';
import { usePopper } from '../../../../hooks';
import { DispositionType } from '../../../../interfaces/IDisposition';
import { MaritalStatus } from '../../../../interfaces/IPerson';
import { BusinessTypes, PartnerKeys } from '../../../../interfaces/ISourceDimensions';
import { useActiveFCRAConsent } from '../../../../queries/disclosures/useDisclosure';
import analytics from '../../../../services/analytics';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import FollowupPopper from '../../../ScheduleCall/FollowupPopper';
import Indicator from '../../../ScheduleCall/Indicator';
import DynamicTips from '../../_components/DynamicTips';
import { useCurrentDispositionSubscriptionContext } from '../../_hooks/useCurrentDispositionSubscription';
import { GuidedSellingExperienceOpenedFrom, LocationState } from '../../navigation';
import ConnectToCall from '../ConnectToCall';
import DataCompletenessTag from '../DataCompletenessTag';
import EngagementBrand from '../EngagementBrand';
import LeadDispositionsHeader from '../LeadDispositionsHeader';
import LeadType from '../LeadType';
import PersonTimeHeader from '../PersonTimeHeader';
import RecentLeads from '../RecentLeads';
import ScheduleCallButton from '../ScheduleCallButton';
import TakeOverLead from '../TakeOverLead';
import TestLead from '../TestLead';

const PersonHeader = () => {
  const { currentCallId } = useCallState();
  const { currentDisposition } = useCurrentDispositionSubscriptionContext();
  const { personGid, person, leadGid, lead, callLogId, candidateGid, isPersonLinkedToActiveCall, sourceDimensions } =
    useGuidedSellingExperienceContext();
  const navigate = useNavigate();

  const { data: isFcraDisclosureAccepted, isSuccess: isDisclosureLoaded } = useActiveFCRAConsent(personGid);

  const { state } = useLocation();
  const openedFrom = (state as LocationState)?.openedFrom;
  const openedFromSearch = openedFrom === GuidedSellingExperienceOpenedFrom.PeopleSearch;

  const { triggerPopper, PopperComponent, popperProps, elementRef } = usePopper({
    placement: 'bottom',
    disablePortal: true,
    modifiers: [
      {
        name: 'preventOverflow',
        enabled: true,
        options: {
          altAxis: false,
          altBoundary: true,
          tether: true,
          rootBoundary: 'document',
          padding: 8
        }
      },
      {
        name: 'offset',
        enabled: true,
        options: {
          offset: [0, 8]
        }
      }
    ],
    style: { zIndex: 999 }
  });

  const shouldRenderLifeTip =
    person?.marital_status === MaritalStatus.Married &&
    sourceDimensions?.business_type === BusinessTypes.HomeOrigination &&
    sourceDimensions.partner === PartnerKeys.NAF;

  return (
    <FlexBox
      id="heading"
      justifySpaceBetween
      p={spacings.px8}
      gap={spacings.px8}
      borderBottom
      customCss={css`
        min-height: 52px;
        flex-wrap: wrap;
      `}
    >
      <FlexBox gap={spacings.px8} alignItemsCenter>
        <FlexBox gap={spacings.px4} alignItemsCenter>
          {openedFromSearch && (
            <ButtonWithoutBorder
              onClick={() => {
                analytics.track('Back to search results clicked', {
                  call_log_id: callLogId,
                  person_gid: personGid,
                  lead_gid: leadGid,
                  candidate_gid: candidateGid
                });

                navigate(-1);
              }}
              data-testid="back-to-search-results"
            >
              &#8592; Back
            </ButtonWithoutBorder>
          )}
          <RecentLeads />
          {person && (
            <Paragraph
              data-testid="scouting-person-title"
              type="large"
              bold
              className="fs-mask"
              singleLine
              customCss={css`
                max-width: 320px;
              `}
            >
              {person.name}
            </Paragraph>
          )}
          {lead && (
            <Copy value={lead.id.toString()} withIcon={false}>
              <Paragraph type="large" color={colors.grey60} singleLine>
                ID: {lead.id}
              </Paragraph>
            </Copy>
          )}
        </FlexBox>
        {leadGid && (
          <Link to={`/leads/gid/${leadGid}`}>
            <OpenInNewIcon color={colors.black} />
          </Link>
        )}
        <Container
          customCss={css`
            @media (width < 1120px) {
              display: none;
            }
          `}
        >
          <PersonTimeHeader />
        </Container>
        <FlexBox
          alignItemsCenter
          gap={spacings.px8}
          customCss={css`
            @media (width < 1450px) {
              display: none;
            }
          `}
        >
          <EngagementBrand />
          <LeadType />
          <TestLead />
          <DataCompletenessTag />
        </FlexBox>
        <Container
          innerRef={elementRef}
          css={css`
            @media (width >= 1450px) {
              display: none;
            }
          `}
        >
          <ButtonWithoutBorder
            onClick={triggerPopper}
            customCss={css`
              padding: 0;
            `}
          >
            <Paragraph type="large" color={colors.grey60}>
              ...
            </Paragraph>
          </ButtonWithoutBorder>
          <PopperComponent {...popperProps}>
            <FlexBox
              ph={spacings.px24}
              pv={spacings.px12}
              columnDirection
              backgroundColor={colors.white}
              roundBorder
              border
              boxShadow
              gap={spacings.px8}
            >
              <EngagementBrand />
              <LeadType />
              <TestLead />
              <DataCompletenessTag />
            </FlexBox>
          </PopperComponent>
        </Container>
      </FlexBox>
      <FlexBox gap={spacings.px12} alignItemsCenter>
        {!isPersonLinkedToActiveCall ? (
          <>
            {currentDisposition && (
              <Paragraph color={colors.grey60} singleLine data-testid="current-disposition-label">
                {dispositionLabel(currentDisposition)}
              </Paragraph>
            )}
            {personGid && (
              <Indicator withPopper personGid={personGid} leadGid={leadGid} candidateGid={candidateGid} disabled />
            )}
            <ConnectToCall />
          </>
        ) : (
          <>
            {leadGid && currentDisposition && (
              <Paragraph color={colors.grey60} singleLine data-testid="current-disposition-label">
                {dispositionLabel(currentDisposition)}
              </Paragraph>
            )}
            {!leadGid && personGid && (
              <Paragraph color={colors.grey60} singleLine data-testid="current-disposition-label">
                {Translations.dispositionType(DispositionType.NewRequest)}
              </Paragraph>
            )}
            {featureFlags.dynamicTips && personGid && isDisclosureLoaded && currentCallId && (
              <>
                <DynamicTips
                  key={personGid}
                  isDisclosureAccepted={isFcraDisclosureAccepted}
                  withLifeTip={shouldRenderLifeTip}
                />
              </>
            )}

            {lead && <TakeOverLead lead={lead} />}
            <LeadDispositionsHeader />

            {leadGid && featureFlags.scheduleFollowUpUI && (
              <FollowupPopper personGid={personGid} engagementGid={leadGid} />
            )}
            <ScheduleCallButton />
            {personGid && <Indicator withPopper personGid={personGid} leadGid={leadGid} candidateGid={candidateGid} />}
          </>
        )}
      </FlexBox>
    </FlexBox>
  );
};

export default PersonHeader;
