import { useQueryClient } from '@tanstack/react-query';
import Skeleton from 'react-loading-skeleton';
import * as yup from 'yup';

import { useToggle } from '../../hooks';
import { IMaticPolicy, IPerson } from '../../interfaces';
import { DocumentOwnerType } from '../../interfaces/IDocument';
import useAssignees from '../../queries/assignees/useAssignees';
import useCarrier from '../../queries/carriers/useCarrier';
import useDocumentTypes from '../../queries/document_types/useDocumentTypes';
import { DOCS_QUERY_KEY, useCreateDocument } from '../../queries/people/person_documents/usePersonDocuments';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import alert from '../core/Alert';
import Button, { ButtonSize, ButtonVariant } from '../core/buttons/Button';
import FlexBox from '../core/FlexBox';
import FormModal from '../core/FormModal';
import { RadioGroupField } from '../core/forms/fields';
import Paragraph from '../core/Paragraph';
import Text from '../core/Text';
import { collectPolicyAssetsAddresses } from '../GenerateInvoiceModal/helpers';
import generateDocxReport, { CarrierAddress } from '../PolicyList/generateDocxReport';

const GenerateInvoiceOnPolicy = ({ policy, person }: { policy: IMaticPolicy; person: IPerson }) => {
  const [generateInvoice, toggleGenerateInvoice] = useToggle(false);
  const queryClient = useQueryClient();
  const { data: policyCarrier, isLoading: isLoadingPolicyCarrier } = useCarrier(policy.carrier.id);
  const { data: assignees, isLoading: isLoadingAssignees } = useAssignees();
  const { data: docTypes, isLoading: isLoadingDocumentTypes } = useDocumentTypes();
  const { mutateAsync: createDocument, isPending } = useCreateDocument({ attachDocs: false });

  return (
    <>
      <Button variant={ButtonVariant.Simple} size={ButtonSize.Small} onClick={toggleGenerateInvoice}>
        <Text type="small" bold>
          Generate
        </Text>
      </Button>

      {generateInvoice && (
        <FormModal
          title="Generate invoice"
          confirmText="Generate"
          cancelText="Cancel"
          cancelHandler={toggleGenerateInvoice}
          confirmHandler={values => {
            const reportTitle = `${person?.first_name}_${person?.last_name}_` + `${policy.policy_number}_invoice.docx`;

            generateDocxReport({
              title: reportTitle,
              person,
              policy,
              carrier: {
                address: policyCarrier?.[values.mailing_method as CarrierAddress],
                name: policyCarrier?.name
              },
              agentName: assignees?.find(({ id }) => id === policy.agent_id)?.name,
              assetsAddresses: collectPolicyAssetsAddresses(policy),
              saveFile: false
            }).then(blob => {
              if (blob) {
                const invoiceDocTypeId = docTypes?.document_types?.find(
                  document_type => document_type.key === 'invoice'
                )?.id;

                if (invoiceDocTypeId) {
                  createDocument({
                    personGid: person.gid,
                    request: {
                      file: new File([blob], reportTitle),
                      uid: crypto.randomUUID(),
                      owner_id: policy.id,
                      owner_type: DocumentOwnerType.Policy,
                      document_type_id: invoiceDocTypeId
                    }
                  }).then(() => {
                    queryClient.invalidateQueries({ queryKey: [DOCS_QUERY_KEY, person.gid] });
                    alert({
                      message: 'Invoice was added to your files.',
                      options: {
                        autoClose: 2000
                      }
                    }).info();
                  });
                }
              }
            });
            toggleGenerateInvoice();
          }}
          confirmationInProgress={isPending}
          validationSchema={yup.object({ mailing_method: yup.string().required('Please choose mailing method') })}
          initialValues={{ mailing_method: CarrierAddress.RemittanceAddress }}
          renderForm={() => {
            if (isLoadingPolicyCarrier || isLoadingAssignees || isLoadingDocumentTypes) {
              return <Skeleton height={100} />;
            }
            return (
              <FlexBox columnDirection gap={spacings.px24}>
                <RadioGroupField
                  columnDirection
                  label={() => <Paragraph bold>Choose mailing method</Paragraph>}
                  id="mailing_method"
                  name="mailing_method"
                  options={[
                    policyCarrier?.remittance_address?.full && {
                      id: CarrierAddress.RemittanceAddress,
                      value: CarrierAddress.RemittanceAddress,
                      description: (
                        <Text type="small" color={colors.grey60}>
                          {policyCarrier?.remittance_address?.full}
                        </Text>
                      ),
                      label: 'Remittance address'
                    },
                    policyCarrier?.overnight_address?.full && {
                      id: CarrierAddress.OvernightAddress,
                      label: 'Overnight address',
                      description: (
                        <Text type="small" color={colors.grey60}>
                          {policyCarrier?.overnight_address?.full}
                        </Text>
                      ),
                      value: CarrierAddress.OvernightAddress
                    }
                  ].filter(Boolean)}
                />
              </FlexBox>
            );
          }}
        />
      )}
    </>
  );
};

export default GenerateInvoiceOnPolicy;
