/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';

import Button, { ButtonSize, ButtonVariant } from '../../components/core/buttons/Button';
import ButtonWithoutBorder from '../../components/core/buttons/ButtonWithoutBorder';
import IconButton, { ButtonIcons } from '../../components/core/buttons/IconButton';
import FlexBox from '../../components/core/FlexBox';
import { DateInputField, SelectField, TextareaField } from '../../components/core/forms/fields';
import FormError from '../../components/core/forms/fields/FormError';
import { ArrowRight2Icon, CalendarIcon, CarIcon, HomeIcon } from '../../components/core/icons';
import Paragraph from '../../components/core/Paragraph';
import SwitchToggle from '../../components/core/SwitchToggle';
import Text from '../../components/core/Text';
import { IPerson } from '../../interfaces';
import { InsurableInterest } from '../../interfaces/IPolicyType';
import { IProposal } from '../../interfaces/IProposal';
import { ScheduledInteractionFlow } from '../../interfaces/IScheduledCall';
import { usePersonProposals } from '../../queries/people/proposals/usePersonProposal';
import { PERSON_QUERY_KEY } from '../../queries/people/usePerson';
import useUpdatePersonPhone from '../../queries/people/useUpdatePersonPhone';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import { phoneFormatter } from '../../utils/formatter';
import { createProposalOptions, ProposalCustomOption } from '../ProposalBuilder/_components';
import { quoteOptionFormatting } from '../ProposalBuilder/_helpers';
import PhoneEditor from './PhoneEditor';

interface IFollowupFormProps {
  person: IPerson;
  engagementGid: string;
  editPhones: boolean;
  setEditPhones: (b: boolean) => void;
  setFieldValue: (field: string, value: string | boolean) => void;
  onDiscard: () => void;
  values: {
    cadence: ScheduledInteractionFlow;
    date: string;
    time: string;
    phone: string;
    note: string;
    proposal: string;
    include_proposal: boolean;
  };
  isSubmitting: boolean;
}

const ProposalSharing = ({
  values,
  setFieldValue,
  proposals
}: {
  values: IFollowupFormProps['values'];
  setFieldValue: IFollowupFormProps['setFieldValue'];
  proposals: IProposal[] | undefined;
}) => {
  const proposalsOptions = createProposalOptions(proposals || []);
  const selectedProposal = proposals?.find(proposal => proposal.gid === values.proposal);

  return (
    <>
      <Paragraph bold>Proposal sharing</Paragraph>
      <FlexBox alignItemsCenter gap={spacings.px4} justifySpaceBetween>
        <Paragraph>Add this proposal to the follow-up</Paragraph>
        <SwitchToggle
          checked={values.include_proposal}
          onChange={() => {
            setFieldValue('include_proposal', !values.include_proposal);
          }}
          disabled={false}
        />
      </FlexBox>
      {values.include_proposal && (
        <SelectField
          preserveErrorSpace={false}
          required
          id="proposal"
          key="proposal"
          name="proposal"
          label="Proposal version"
          isLoading={!proposals}
          options={proposalsOptions}
          customComponents={{ Option: ProposalCustomOption as unknown as React.ReactNode }}
        />
      )}
      {selectedProposal && (
        <FlexBox border borderColor={colors.grey10} roundBorder p={spacings.px12} gap={spacings.px12}>
          {selectedProposal.proposal_options?.[0]?.quotes.map(quote => (
            <FlexBox
              key={quote.gid}
              alignItemsCenter
              justifySpaceBetween
              customCss={css`
                flex: 1;
              `}
            >
              <FlexBox alignItemsCenter gap={spacings.px16}>
                {quote.attributes.insurable_interest === InsurableInterest.RealProperty ? <HomeIcon /> : <CarIcon />}
                <div
                  css={css`
                    background-position: left;
                    background-repeat: no-repeat;
                    background-size: contain;
                    height: 30px;
                    width: 80px;
                    ${quote.attributes.carrier.logo ? `background-image: url(${quote.attributes.carrier.logo})` : ''};
                  `}
                  title={quote.attributes.carrier.name}
                />
              </FlexBox>
              <Text bold color={colors.black}>
                {quoteOptionFormatting({
                  premium: quote.attributes.premium || 0,
                  term_months: quote.attributes.payment_options?.[0]?.term_months as 6 | 12 | undefined
                })}
              </Text>
            </FlexBox>
          ))}
        </FlexBox>
      )}
    </>
  );
};

const DateTimeSelects = () => {
  // TODO: NEW api call to telephony to get available times

  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 16px;
      `}
    >
      <DateInputField
        preserveErrorSpace={false}
        prefixIcon={<CalendarIcon />}
        required
        key="date"
        label="Date"
        name="date"
        id="date"
      />
      <SelectField
        preserveErrorSpace={false}
        menuMinWidth="0px"
        required
        id="time"
        label="Time"
        name="time"
        key="time"
        options={[
          { key: '10:00', value: '10:00' },
          { key: '11:00', value: '11:00' },
          { key: '12:00', value: '12:00' }
        ]}
      />
    </div>
  );
};

const FollowupForm = ({
  person,
  editPhones,
  engagementGid,
  setEditPhones,
  setFieldValue,
  values,
  onDiscard,
  isSubmitting
}: IFollowupFormProps) => {
  const [step, setStep] = React.useState<1 | 2>(1);

  const queryClient = useQueryClient();
  const { mutateAsync: updatePhone } = useUpdatePersonPhone(() => {
    queryClient.invalidateQueries({ queryKey: [PERSON_QUERY_KEY, person.gid] });
  });

  const phoneOptions = [
    { key: person.phone, value: phoneFormatter(person.phone), postfix: 'Primary' },
    { key: person.secondary_phone, value: phoneFormatter(person.secondary_phone), postfix: 'Secondary' },
    { key: person.business_phone, value: phoneFormatter(person.business_phone), postfix: 'Business' }
  ].filter(item => !!item.key);

  const { data: proposals } = usePersonProposals({
    personGid:
      values.include_proposal && values.cadence === ScheduledInteractionFlow.PipelineHighPriority
        ? person.gid
        : undefined,
    engagementGid
  });

  React.useEffect(() => {
    if (values.include_proposal && !values.proposal && proposals?.[0]) {
      setFieldValue('proposal', proposals[0].gid);
    }
  }, [setFieldValue, values.include_proposal, proposals, values.proposal]);

  return (
    <>
      {editPhones && (
        <PhoneEditor
          person={person}
          cancelBtnHandler={() => {
            setEditPhones(false);
          }}
          confirmBtnHandler={values =>
            updatePhone({
              personGid: person.gid,
              phone: values.phone,
              secondary_phone: values.secondary_phone,
              business_phone: values.business_phone
            }).then(() => {
              setEditPhones(false);
              setFieldValue('phone', '');
            })
          }
        />
      )}

      <FlexBox
        p={spacings.px24}
        columnDirection
        backgroundColor={colors.white}
        roundBorder
        border
        boxShadow
        customCss={css`
          width: 400px;
        `}
        gap={spacings.px24}
      >
        <FlexBox justifySpaceBetween alignItemsCenter>
          <FlexBox gap={spacings.px12} alignItemsCenter>
            {step === 2 && <IconButton color={colors.black} icon={ButtonIcons.ArrowLeft2} onClick={() => setStep(1)} />}
            <Paragraph type="large" bold>
              Schedule follow-up
            </Paragraph>
          </FlexBox>
          {values.cadence === ScheduledInteractionFlow.PipelineHighPriority && (
            <Paragraph type="small" color={colors.grey60}>
              Step {step}/2
            </Paragraph>
          )}
        </FlexBox>
        {step === 1 && (
          <SelectField
            preserveErrorSpace={false}
            required
            id="cadence"
            key="cadence"
            name="cadence"
            label="Cadence"
            options={[
              { label: 'Low priority (sms/emails only)', key: ScheduledInteractionFlow.PipelineLowPriority },
              { label: 'High priority', key: ScheduledInteractionFlow.PipelineHighPriority }
            ]}
          />
        )}
        {values.cadence === ScheduledInteractionFlow.PipelineLowPriority && !values.phone && (
          <FormError id="custom-phone" error="Phone is missing" hasError />
        )}
        {values.cadence === ScheduledInteractionFlow.PipelineHighPriority && step === 1 && (
          <>
            <DateTimeSelects />
            <SelectField
              preserveErrorSpace={false}
              required
              id="phone"
              key="phone"
              name="phone"
              label="Phone"
              options={phoneOptions}
              menuOptions={
                <>
                  <hr
                    css={css`
                      margin: 0 10px 10px 10px;
                    `}
                  />
                  <Button
                    onClick={() => setEditPhones(true)}
                    variant={ButtonVariant.Text}
                    data-testid="modify-phones-button"
                    css={css`
                      margin: 0 5px 10px 10px;
                      text-align: left;
                      width: 100%;
                    `}
                  >
                    Modify phones
                  </Button>
                </>
              }
            />
          </>
        )}
        {step === 2 && <ProposalSharing values={values} setFieldValue={setFieldValue} proposals={proposals} />}
        {step === 1 && (
          <TextareaField
            preserveErrorSpace={false}
            required
            id="note"
            key="note"
            name="note"
            label="Note"
            placeholder="Note here"
          />
        )}
        <FlexBox columnDirection gap={spacings.px12}>
          <FlexBox justifyRight gap={spacings.px24}>
            <ButtonWithoutBorder
              customCss={css`
                color: ${colors.black};
              `}
              onClick={onDiscard}
            >
              Discard
            </ButtonWithoutBorder>
            <Button
              size={ButtonSize.Small}
              key={step}
              loading={isSubmitting}
              type={step === 2 || values.cadence === ScheduledInteractionFlow.PipelineLowPriority ? 'submit' : 'button'}
              onClick={() => {
                step === 1 && values.cadence === ScheduledInteractionFlow.PipelineHighPriority && setStep(2);
              }}
            >
              {values.cadence === ScheduledInteractionFlow.PipelineHighPriority && step === 1 ? (
                <FlexBox alignItemsCenter gap={spacings.px12}>
                  Proceed <ArrowRight2Icon />
                </FlexBox>
              ) : (
                'Schedule follow-up'
              )}
            </Button>
          </FlexBox>
          <Paragraph
            type="tiny"
            color={colors.grey60}
            customCss={css`
              text-align: center;
            `}
          >
            Your progress will be saved unless you discard or close the lead
          </Paragraph>
        </FlexBox>
      </FlexBox>
    </>
  );
};

export default FollowupForm;
