import { useQueryClient } from '@tanstack/react-query';
import { FileRejection, useDropzone } from 'react-dropzone';

import { IMaticPolicy } from '../../interfaces';
import { DocumentOwnerType } from '../../interfaces/IDocument';
import { DocumentType } from '../../queries/document_types/useDocumentTypes';
import { DOCS_QUERY_KEY, useCreateDocument } from '../../queries/people/person_documents/usePersonDocuments';
import { spacings } from '../../theme/variables';
import { formatFileName } from '../../utils/formatter';
import alert from '../core/Alert';
import Button, { ButtonSize, ButtonVariant } from '../core/buttons/Button';
import FlexBox from '../core/FlexBox';
import { UploadIcon } from '../core/icons';
import Text from '../core/Text';
import { acceptedFileTypes, maxSizeValidator } from '../PersonDocumentsTab/helpers';

const UploadPolicyDocument = ({
  documentTypeKey,
  policy,
  docTypes,
  personGid
}: {
  documentTypeKey: string;
  policy: IMaticPolicy;
  docTypes: DocumentType[];
  personGid: string;
}) => {
  const queryClient = useQueryClient();

  const { mutateAsync: createDocument, isPending } = useCreateDocument({ attachDocs: false });
  const documentTypeId = docTypes.find(({ key }) => key === documentTypeKey)?.id;
  const documentTypeTitle = docTypes.find(({ key }) => key === documentTypeKey)?.title;
  const onDrop = async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    const fileToUpload =
      acceptedFiles[0] && documentTypeId
        ? {
            filesize: acceptedFiles[0].size,
            uid: crypto.randomUUID(),
            file: acceptedFiles[0],
            name: formatFileName(acceptedFiles[0].name),
            document_type_key: documentTypeKey,
            document_type_id: documentTypeId,
            document_type_title: documentTypeTitle,
            owner_id: policy.id,
            owner_type: DocumentOwnerType.Policy
          }
        : null;
    fileToUpload &&
      (await createDocument({
        personGid,
        request: fileToUpload
      }));

    const filesToReject = fileRejections.map(({ errors, file }) => ({ file, error: errors[0]!.message }));
    filesToReject[0] &&
      alert({
        title: 'Error uploading files',
        items: filesToReject.map(({ file, error }) => `${file.name} - ${error}`)
      }).error();
    return queryClient.invalidateQueries({ queryKey: [DOCS_QUERY_KEY, personGid] });
  };
  const { getRootProps, getInputProps } = useDropzone({
    useFsAccessApi: false,
    maxFiles: 1,
    validator: maxSizeValidator,
    onDrop,
    accept: acceptedFileTypes,
    disabled: false
  });

  return (
    <>
      <FlexBox {...getRootProps()}>
        <input {...getInputProps()} data-testid="button-input" className="dz-hidden-input" />
        <Button variant={ButtonVariant.Simple} size={ButtonSize.Small} type="button" disabled={isPending}>
          <FlexBox alignItemsCenter gap={spacings.px4}>
            <UploadIcon width={16} />{' '}
            <Text type="small" bold>
              Upload
            </Text>
          </FlexBox>
        </Button>
      </FlexBox>
    </>
  );
};

export default UploadPolicyDocument;
