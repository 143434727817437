/** @jsxImportSource @emotion/react */
/* FastField used here due to expensive rerenders of DateInput's Calendar */
import { SerializedStyles } from '@emotion/react';
import { FastField, FieldProps } from 'formik';

import { QuestionVerificationStatus } from '../../../../interfaces/IWorkflow';
import { InputSize, LabelSize } from '../../../../theme/variables';
import DateInput, { DateInputProps } from '../../inputs/Date';
import FormError from './FormError';
import LabeledInput from './LabeledInput';

export interface DateFieldProps extends Partial<DateInputProps> {
  label: string | JSX.Element;
  id: string;
  name: string;
  required?: boolean;
  secondary?: boolean;
  customLabelCss?: SerializedStyles;
  labelSize?: LabelSize;
  inputSize?: InputSize;
  testId?: string;
  maxDate?: Date;
  minDate?: Date;
  prefixIcon?: JSX.Element;
  preserveErrorSpace?: boolean;
  verificationStatus?: QuestionVerificationStatus;
}

const DateInputField = ({
  label,
  id,
  name,
  required,
  testId,
  inline,
  secondary = false,
  customLabelCss,
  labelSize,
  inputSize,
  verificationStatus,
  prefixIcon,
  preserveErrorSpace = true,
  ...rest
}: DateFieldProps): JSX.Element => (
  <LabeledInput
    id={id}
    required={required}
    label={label}
    inline={inline}
    secondary={secondary}
    disabled={rest.disabled}
    customCss={customLabelCss}
    labelSize={labelSize}
    inputSize={inputSize}
    verificationStatus={verificationStatus}
  >
    <FastField name={name}>
      {({ field: { onChange, value, onBlur }, meta, form }: FieldProps) => {
        const hasError = meta.error && meta.touched;

        const onDayChange = (value: string | Date) => {
          onChange({
            target: {
              name,
              value
            }
          });
        };

        return (
          <>
            <DateInput
              {...rest}
              testId={testId}
              value={value}
              id={id}
              inline={inline}
              inputSize={inputSize}
              onDayChange={onDayChange}
              inputProps={{
                id,
                name,
                onBlur: () => onBlur({ target: { name, value } }),
                hasError: !!hasError,
                'aria-invalid': !!hasError,
                'aria-errormessage': `${id}-error`,
                disabled: rest.disabled || form.status === 'disabled',
                fsMask: rest.fsMask,
                customCss: rest.customCss,
                prefixIcon
              }}
            />

            {inline || !preserveErrorSpace ? (
              hasError && <FormError id={id} hasError={!!hasError} error={meta.error} />
            ) : (
              <FormError id={id} hasError={!!hasError} error={meta.error} />
            )}
          </>
        );
      }}
    </FastField>
  </LabeledInput>
);

export default DateInputField;
