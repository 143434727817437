/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';

import { useToggle } from '../../hooks';
import { IDocument } from '../../interfaces';
import { documentFileDownloadUrl } from '../../interfaces/IDocument';
import PDFFileViewer from '../../pages/PostSalesExperience/_components/PDFFileViewer';
import { DocumentType } from '../../queries/document_types/useDocumentTypes';
import {
  DOCS_QUERY_KEY,
  useDeleteDocument,
  useRetrievePersonDocumentServiceUrl
} from '../../queries/people/person_documents/usePersonDocuments';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import { dateFormatter, fileSizeFormatter } from '../../utils/formatter';
import { PDFContext } from '../common/PDFViewer';
import IconButton, { ButtonIcons } from '../core/buttons/IconButton';
import Container from '../core/Container';
import FlexBox from '../core/FlexBox';
import Modal from '../core/Modal';
import Paragraph from '../core/Paragraph';
import Tag from '../core/Tag';
import Text from '../core/Text';
import VerticalTabContent from '../core/VerticalTabs/VerticalTabContent';
import VerticalTabHeader from '../core/VerticalTabs/VerticalTabHeader';
import { IChannel } from '../Documents/Document';
import { DocumentStatusesTable } from '../Documents/DocumentStatus';

const DocumentDetails = ({
  personGid,
  document,
  setExpandedDocument,
  documentTypes,
  expandedDocumentChannels,
  onTabClose
}: {
  personGid: string;
  document: IDocument;
  setExpandedDocument: (doc: IDocument | null) => void;
  documentTypes: DocumentType[];
  expandedDocumentChannels?: IChannel[] | null;
  onTabClose: () => void;
}) => {
  const queryClient = useQueryClient();
  const [deleteDocumentModal, toggleDeleteDocument] = useToggle(false);

  const [fileToPreview, setFileToPreview] = React.useContext(PDFContext);
  const fileIsPreviewed = document.filesize === fileToPreview?.filesize;
  const documentTypeTitle = documentTypes.find(({ key }) => key === document.document_type_key)?.title;
  const { mutateAsync: retrieveServiceUrl } = useRetrievePersonDocumentServiceUrl();
  const { mutateAsync: deleteDocument } = useDeleteDocument({ invalidate: true });

  React.useEffect(() => {
    if (document) {
      personGid &&
        retrieveServiceUrl({ personGid, documentId: document.id }).then(url => {
          setFileToPreview({ ...document, link: url });
        });
    }
    return () => {
      setFileToPreview(null);
    };
  }, [document, personGid, retrieveServiceUrl, setFileToPreview]);

  return (
    <>
      <VerticalTabHeader onTabClose={onTabClose}>
        <FlexBox columnDirection>
          <FlexBox gap={spacings.px4} fitParentHeight>
            <IconButton icon={ButtonIcons.ArrowLeft2} onClick={() => setExpandedDocument(null)} color={colors.black} />
            <Text bold className="fs-mask">
              {document.name}
            </Text>

            <Tag
              transparent
              label={documentTypeTitle || 'Unknown'}
              textColor={documentTypeTitle ? colors.violet : colors.grey60}
              backgroundColor={documentTypeTitle ? colors.violet : colors.grey60}
              border
              textType="tiny"
              customCss={css`
                padding: 2px 4px;
              `}
            />
          </FlexBox>
          <Paragraph type="tiny" color={colors.grey60} singleLine ml={spacings.px32}>
            {fileSizeFormatter(document.filesize)}
            <span
              css={css`
                position: relative;
                bottom: 4px;
                margin: 4px;
              `}
            >
              .
            </span>
            Uploaded{' '}
            {document.author?.name && (
              <>
                by {document.author.name}
                <span
                  css={css`
                    position: relative;
                    bottom: 4px;
                    margin: 4px;
                  `}
                >
                  .
                </span>
              </>
            )}
            on {dateFormatter(document.uploaded)}
          </Paragraph>
        </FlexBox>
      </VerticalTabHeader>
      <VerticalTabContent>
        <FlexBox columnDirection gap={spacings.px16} mv={spacings.px12}>
          <FlexBox gap={spacings.px4} alignItemsCenter justifyRight>
            <IconButton
              focus={fileIsPreviewed}
              icon={ButtonIcons.FullScreen}
              onClick={() => {
                if (fileIsPreviewed) {
                  setFileToPreview(undefined);
                } else if (document) {
                  if (document) {
                    personGid &&
                      retrieveServiceUrl({ personGid, documentId: document.id }).then(url => {
                        setFileToPreview({ ...document, link: url });
                      });
                  }
                }
              }}
            />
            <Container
              backgroundColor={colors.grey30}
              css={css`
                width: 1px;
                height: 20px;
              `}
            />
            <IconButton icon={ButtonIcons.Delete} onClick={toggleDeleteDocument} color={colors.statusRed} />
            <Container
              backgroundColor={colors.grey30}
              css={css`
                width: 1px;
                height: 20px;
              `}
            />
            <a href={documentFileDownloadUrl(document)} target="_blank" rel="noreferrer">
              <IconButton icon={ButtonIcons.Download} />
            </a>
          </FlexBox>

          <FlexBox
            customCss={css`
              max-height: 496px;
            `}
          >
            <PDFFileViewer />
          </FlexBox>
          {deleteDocumentModal && (
            <Modal
              title="Are you sure you want to delete this file?"
              confirmText="Delete File"
              cancelHandler={toggleDeleteDocument}
              confirmHandler={() => {
                deleteDocument({ personGid: personGid!, documentId: document.id }).then(() =>
                  queryClient.invalidateQueries({ queryKey: [DOCS_QUERY_KEY, personGid] })
                );
                toggleDeleteDocument();
                setExpandedDocument(null);
              }}
              dangerModal
            >
              <FlexBox columnDirection>
                <Text>
                  This action cannot be undone. Deleting this file will remove it permanently from the system.
                </Text>
                <Text bold>File name: {document.name}</Text>
              </FlexBox>
            </Modal>
          )}
        </FlexBox>
        {expandedDocumentChannels?.length ? (
          <FlexBox columnDirection gap={spacings.px16}>
            <Text bold>Sending rules</Text>
            <DocumentStatusesTable channels={expandedDocumentChannels} />
          </FlexBox>
        ) : null}
      </VerticalTabContent>
    </>
  );
};

export default DocumentDetails;
