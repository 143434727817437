/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import { IPerson } from '../../interfaces';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import { phoneFormatter } from '../../utils/formatter';
import Copy from '../core/Copy';
import DescriptionList from '../core/DescriptionList';
import FlexBox from '../core/FlexBox';
import Text from '../core/Text';
import EmptyAnswer from '../UIFlow/EmptyAnswer';

const LoanPersonInfo = ({
  title,
  person,
  isLoanPrimary
}: {
  title: string;
  person: IPerson;
  isLoanPrimary: boolean;
}) => {
  return (
    <>
      <Text bold ml={spacings.px4} color={isLoanPrimary ? colors.black : colors.grey30}>
        {title}
      </Text>
      <FlexBox
        alignItemsCenter
        css={css`
          flex-wrap: wrap;
        `}
        justifySpaceBetween
      >
        <DescriptionList
          width={200}
          term={<Text color={isLoanPrimary ? colors.grey60 : colors.grey30}>First name</Text>}
          details={
            <>
              {person.first_name ? (
                <Copy className="fs-mask" value={person.first_name}>
                  <Text singleLine color={isLoanPrimary ? colors.black : colors.grey30}>
                    {person.first_name}
                  </Text>
                </Copy>
              ) : (
                <EmptyAnswer />
              )}
            </>
          }
        />

        <DescriptionList
          width={200}
          term={<Text color={isLoanPrimary ? colors.grey60 : colors.grey30}>Phone</Text>}
          details={
            <>
              {person.phone ? (
                <Copy className="fs-mask" value={phoneFormatter(person.phone)}>
                  <Text singleLine color={isLoanPrimary ? colors.black : colors.grey30}>
                    {phoneFormatter(person.phone)}
                  </Text>
                </Copy>
              ) : (
                <EmptyAnswer />
              )}
            </>
          }
        />
      </FlexBox>

      <FlexBox
        alignItemsCenter
        css={css`
          flex-wrap: wrap;
        `}
        justifySpaceBetween
      >
        <DescriptionList
          width={200}
          term={<Text color={isLoanPrimary ? colors.grey60 : colors.grey30}>Last name</Text>}
          details={
            <>
              {person.last_name ? (
                <Copy className="fs-mask" value={person.last_name}>
                  <Text singleLine color={isLoanPrimary ? colors.black : colors.grey30}>
                    {person.last_name}
                  </Text>
                </Copy>
              ) : (
                <EmptyAnswer />
              )}
            </>
          }
        />

        <DescriptionList
          width={200}
          term={<Text color={isLoanPrimary ? colors.grey60 : colors.grey30}>Email</Text>}
          details={
            <>
              {person.email ? (
                <Copy className="fs-mask" value={person.email}>
                  <Text singleLine color={isLoanPrimary ? colors.black : colors.grey30}>
                    {person.email}
                  </Text>
                </Copy>
              ) : (
                <EmptyAnswer />
              )}
            </>
          }
        />
      </FlexBox>
    </>
  );
};

export default LoanPersonInfo;
