/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import { useGuidedSellingExperienceContext } from '../../contexts/GuidedSellingExperienceContext';
import { IDocument, IMaticPolicy } from '../../interfaces';
import {
  DeliverableDocumentStatus,
  DocumentDeliveryChannel,
  DocumentDeliveryStatus,
  IDeliverableDocument,
  RecipientType
} from '../../interfaces/IDocumentDelivery';
import { DocumentType } from '../../queries/document_types/useDocumentTypes';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import { dateFormatter, fileSizeFormatter } from '../../utils/formatter';
import Container from '../core/Container';
import FlexBox from '../core/FlexBox';
import { ChevronLeft, DocumentWithTickIcon } from '../core/icons';
import Paragraph from '../core/Paragraph';
import Tag from '../core/Tag';
import Text from '../core/Text';
import DocumentStatus from './DocumentStatus';
import GenerateInvoiceOnPolicy from './GenerateInvoiceOnPolicy';
import UploadPolicyDocument from './UploadPolicyDocument';

export interface IChannel {
  channel: DocumentDeliveryChannel;
  document_status: DeliverableDocumentStatus;
  delivery_status: DocumentDeliveryStatus;
  recipient_type: RecipientType;
  sent_at: string;
  last_send_try_at: string | null;
}

const responsibleHash = {
  cst: 'CST',
  agent: 'Agent'
} as const;

export interface IExtendedDocument extends IDeliverableDocument {
  channel: DocumentDeliveryChannel;
  recipient_type: RecipientType;
  delivery_status: DocumentDeliveryStatus;
  updated_at: string;
  last_send_try_at: string | null;
  responsible: 'cst' | 'agent';
}

const Document = ({
  doc,
  documentType,
  channels,
  policy,
  docTypes,
  isDeliveryDocument,
  onDocumentExpand,
  responsible
}: {
  doc: IDocument | null;
  documentType?: string | null;
  channels?: IChannel[];
  policy?: IMaticPolicy;
  docTypes: DocumentType[] | undefined;
  isDeliveryDocument?: boolean;
  onDocumentExpand?: () => void;
  responsible?: 'cst' | 'agent';
}) => {
  const { person } = useGuidedSellingExperienceContext();

  const documentTypeTitle = docTypes?.find(({ key }) => key === documentType)?.title;
  return (
    <FlexBox
      css={css`
        max-width: 768px;
      `}
      border
      roundBorder
      mt={spacings.px12}
      p={spacings.px12}
      justifySpaceBetween
      alignItemsCenter
      gap={spacings.px12}
    >
      <FlexBox
        gap={spacings.px12}
        alignItemsCenter
        css={css`
          max-width: 55%;
        `}
      >
        <Container border roundBorder p={spacings.px4}>
          <Container roundBorder p={spacings.px8} backgroundColor={colors.grey5}>
            <DocumentWithTickIcon color={doc ? colors.statusGreen : colors.grey30} height={24} width={24} />
          </Container>
        </Container>

        {doc ? (
          <FlexBox columnDirection fitParentWidth gap={spacings.px8}>
            <FlexBox alignItemsCenter gap={spacings.px8}>
              <Text className="fs-mask" singleLine title={doc.name} type="small">
                {doc.name}
              </Text>
              <Tag
                transparent
                label={documentTypeTitle || 'Unknown'}
                textColor={documentTypeTitle ? colors.violet : colors.grey60}
                backgroundColor={documentTypeTitle ? colors.violet : colors.grey60}
                border
                textType="tiny"
                customCss={css`
                  padding: 2px 4px;
                `}
              />
            </FlexBox>
            <Paragraph type="tiny" color={colors.grey60} singleLine>
              {fileSizeFormatter(doc.filesize)}
              <span
                css={css`
                  position: relative;
                  bottom: 4px;
                  margin: 4px;
                `}
              >
                .
              </span>
              Uploaded{' '}
              {doc.author?.name && (
                <>
                  by {doc.author.name}
                  <span
                    css={css`
                      position: relative;
                      bottom: 4px;
                      margin: 4px;
                    `}
                  >
                    .
                  </span>
                </>
              )}
              on {dateFormatter(doc.uploaded)}
            </Paragraph>
          </FlexBox>
        ) : (
          <FlexBox columnDirection justifySpaceBetween>
            <Text color={colors.grey60} type="small">
              {documentTypeTitle || 'Unknown'}
            </Text>
            {responsible && (
              <Text italic color={colors.grey60} type="small">
                {responsibleHash[responsible]} is responsible to collect
              </Text>
            )}
          </FlexBox>
        )}
      </FlexBox>
      <FlexBox alignItemsCenter gap={spacings.px16}>
        {isDeliveryDocument && <DocumentStatus channels={channels} document={doc} />}
        {!doc && documentType !== 'invoice' && docTypes && documentType && policy && (
          <UploadPolicyDocument
            personGid={person!.gid}
            docTypes={docTypes}
            documentTypeKey={documentType}
            policy={policy}
          />
        )}
        {person && documentType === 'invoice' && !doc && policy && (
          <GenerateInvoiceOnPolicy policy={policy} person={person} />
        )}
      </FlexBox>
      {onDocumentExpand && doc && (
        <ChevronLeft
          css={css`
            transform: rotate(180deg);
            transition: transform 0.2s;
            cursor: pointer;
          `}
          color={colors.black}
          onClick={() => onDocumentExpand()}
        />
      )}
    </FlexBox>
  );
};

export default Document;
