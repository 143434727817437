/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import * as yup from 'yup';

import Button, { ButtonSize, ButtonVariant } from '../../components/core/buttons/Button';
import Container from '../../components/core/Container';
import FlexBox from '../../components/core/FlexBox';
import BaseForm from '../../components/core/forms/BaseForm';
import { ArrowDownIcon } from '../../components/core/icons';
import Text from '../../components/core/Text';
import { Configuration } from '../../constants';
import { usePopper } from '../../hooks';
import { ScheduledInteractionFlow } from '../../interfaces/IScheduledCall';
import usePerson from '../../queries/people/usePerson';
import { useCreateScheduledInteractions } from '../../queries/scheduled_calls/useScheduledInteractions';
import authInfo from '../../services/authInfo';
import { spacings } from '../../theme/variables';
import { REQUIRED_MESSAGE, requiredField } from '../../utils/yupRules';
import FollowupForm from './FollowupForm';

const FollowupPopper = ({
  personGid,
  disabled = false,
  engagementGid
}: {
  personGid: string | undefined;
  engagementGid: string;
  disabled?: boolean;
}) => {
  const [editPhones, setEditPhones] = React.useState(false);

  const { data: person } = usePerson(personGid);

  const { setAnchorEl, anchorEl, triggerPopper, PopperComponent, popperProps, elementRef } = usePopper({
    placement: 'bottom',
    disablePortal: true,
    modifiers: [
      {
        name: 'preventOverflow',
        enabled: true,
        options: {
          altAxis: false,
          altBoundary: true,
          tether: true,
          rootBoundary: 'document',
          padding: 8
        }
      },
      {
        name: 'offset',
        enabled: true,
        options: {
          offset: [0, 8]
        }
      }
    ],
    style: { zIndex: 999 }
  });

  const { mutateAsync: createScheduledInteraction } = useCreateScheduledInteractions({ throwOnError: false });

  if (!person) {
    return null;
  }

  return (
    <Container p={spacings.px4} innerRef={editPhones ? null : elementRef}>
      <Button onClick={triggerPopper} size={ButtonSize.Small} variant={ButtonVariant.Simple} disabled={disabled}>
        <FlexBox gap={spacings.px4} alignItemsCenter>
          <Text
            bold
            color="inherit"
            customCss={css`
              white-space: nowrap;
            `}
          >
            Schedule follow-up
          </Text>
          <ArrowDownIcon
            css={css`
              ${anchorEl && 'transform: rotate(180deg)'};
              transition: transform 0.2s ease-in-out;
            `}
          />
        </FlexBox>
      </Button>
      <PopperComponent {...popperProps}>
        <BaseForm
          pb={spacings.px0}
          pl={spacings.px0}
          pr={spacings.px0}
          pt={spacings.px0}
          customControls
          validationSchema={yup.object().shape({
            cadence: requiredField,
            note: requiredField,
            phone: requiredField,

            date: yup.string().when('cadence', {
              is: (cadence: string) => cadence === ScheduledInteractionFlow.PipelineHighPriority,
              then: schema => schema.required(REQUIRED_MESSAGE),
              otherwise: undefined
            }),
            time: yup.string().when('cadence', {
              is: (cadence: string) => cadence === ScheduledInteractionFlow.PipelineHighPriority,
              then: schema => schema.required(REQUIRED_MESSAGE),
              otherwise: undefined
            }),
            proposal: yup.string().when(['include_proposal', 'cadence'], {
              is: (include_proposal: boolean, cadence: string) =>
                include_proposal && cadence === ScheduledInteractionFlow.PipelineHighPriority,
              then: schema => schema.required(REQUIRED_MESSAGE),
              otherwise: undefined
            })
          })}
          initialValues={{
            cadence: ScheduledInteractionFlow.PipelineLowPriority,
            date: '',
            include_proposal: true,
            proposal: '',
            time: '',
            phone: person.phone || '',
            note: ''
          }}
          onSubmit={values => {
            const date =
              values.cadence === ScheduledInteractionFlow.PipelineLowPriority
                ? new Date()
                : new Date(`${values.date}T${values.time}`);

            const run_at = date.toISOString();

            return createScheduledInteraction({
              personGid: person!.gid,
              params: {
                run_at,
                phone: values.phone,
                note: values.note,
                assignee_id: authInfo.currentUserId!,
                flow_key: values.cadence,
                proposal_url:
                  values.include_proposal && values.cadence === ScheduledInteractionFlow.PipelineHighPriority
                    ? `${Configuration.customerPortalPublicUrl}/proposal/${values.proposal}`
                    : undefined
              }
            });
          }}
          renderForm={({ values, setFieldValue, isSubmitting }) => {
            const formProps = {
              editPhones,
              setEditPhones,
              person,
              engagementGid,
              setFieldValue,
              values,
              onDiscard: () => setAnchorEl(null),
              isSubmitting
            };

            return <FollowupForm {...formProps} />;
          }}
        />
      </PopperComponent>
    </Container>
  );
};

export default FollowupPopper;
