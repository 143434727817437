/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';

import { FileToConfirm, InvalidFile } from '../../interfaces/IDocument';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import { formatFileName } from '../../utils/formatter';
import Button, { ButtonSize, ButtonVariant } from '../core/buttons/Button';
import FlexBox from '../core/FlexBox';
import Paragraph from '../core/Paragraph';
import Text from '../core/Text';
import { acceptedFileTypes, maxSizeValidator } from './helpers';

interface IDropzone {
  onUpload: (files: FileToConfirm[]) => void;
  onReject: (invalidFiles: InvalidFile[]) => void;
  uploadOneAtTheTime?: boolean;
  inline?: boolean;
  small?: boolean;
  disabled?: boolean;
}

const Dropzone = ({ onUpload, onReject, uploadOneAtTheTime, disabled = false }: IDropzone): JSX.Element => {
  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    const filesToUpload = acceptedFiles.map(file => ({
      filesize: file.size,
      uid: crypto.randomUUID(),
      file,
      name: formatFileName(file.name)
    }));
    onUpload(filesToUpload);

    const filesToReject = fileRejections.map(({ errors, file }) => ({ file, error: errors[0]!.message }));
    onReject(filesToReject);
  };

  const { getRootProps, getInputProps } = useDropzone({
    useFsAccessApi: false,
    maxFiles: uploadOneAtTheTime ? 1 : undefined,
    validator: maxSizeValidator,
    onDrop,
    accept: acceptedFileTypes,
    disabled
  });

  return (
    <FlexBox
      {...getRootProps()}
      justifyCenter
      p={spacings.px24}
      fitParentWidth
      columnDirection
      roundBorder
      gap={spacings.px24}
      alignItemsCenter
      css={css`
        transition: opacity 0.3s;
        border: 1px dashed ${colors.grey30};
      `}
    >
      <Paragraph bold>
        Drag and drop files from your computer here or{' '}
        <Button
          variant={ButtonVariant.Text}
          size={ButtonSize.Small}
          type="button"
          disabled={disabled}
          css={css`
            white-space: nowrap;
          `}
        >
          click here
        </Button>
        <input {...getInputProps()} data-testid="button-input" className="dz-hidden-input" />
      </Paragraph>

      <Text
        color={colors.grey60}
        type="small"
        customCss={css`
          text-align: center;
        `}
      >
        Supported document formats – PDF, DOCX, up to 30MB
      </Text>
    </FlexBox>
  );
};

export default Dropzone;
