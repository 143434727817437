/** @jsxImportSource @emotion/react */

import { useQueryClient } from '@tanstack/react-query';
import { Formik } from 'formik';
import * as React from 'react';
import * as yup from 'yup';

import { Translations } from '../../constants';
import { ILender, ILoan, IMaticPolicy } from '../../interfaces';
import { CompanyType, servicerOrLender } from '../../interfaces/ILender';
import {
  buildCompanyOptions,
  buildVerifiedLendersOptions,
  officerValidation,
  processorValidation
} from '../../pages/Customer/CustomerLoans/helpers';
import useVerifiedLenders from '../../queries/lenders/useVerifiedLenders';
import { PERSON_MATIC_POLICIES_QUERY_KEY } from '../../queries/people/person_policies/usePersonMaticPolicies';
import useUpdatePolicy, { UpdatePolicyRequest } from '../../queries/people/person_policies/useUpdatePolicy';
import { PERSON_LOANS_QUERY_KEY, updateLoan, UpdateLoanRequest } from '../../queries/people/usePersonLoans';
import authInfo from '../../services/authInfo';
import { LabelSize, spacings } from '../../theme/variables';
import { phoneFormatter } from '../../utils/formatter';
import { emailField, maxDate, oldDate, phoneField } from '../../utils/yupRules';
import Container from '../core/Container';
import CopyableAddress from '../core/CopyableAddress';
import { DateInputField, InputField, NumericField, PhoneField, SelectField } from '../core/forms/fields';
import LabeledInput from '../core/forms/fields/LabeledInput';
import { DollarIcon } from '../core/icons';
import Text from '../core/Text';

const SelectedVerifiedCompanyInfo = ({
  verifiedLenders,
  servicerGid,
  lenderGid
}: {
  verifiedLenders: ILender[] | undefined;
  servicerGid: string | undefined;
  lenderGid: string | undefined;
}) => {
  const selectedVerifiedCompany = verifiedLenders?.find(
    lender => lender.gid === servicerGid || lender.gid === lenderGid
  );

  if (!selectedVerifiedCompany) {
    return null;
  }

  const mortgageeAddress = selectedVerifiedCompany.mortgagee_address;

  return mortgageeAddress ? (
    <LabeledInput
      labelSize={LabelSize.Small}
      id="placeholder-address-label"
      label="Address"
      inline
      required={false}
      disabled={false}
      secondary={false}
    >
      <CopyableAddress address={mortgageeAddress} ph={spacings.px8} />
    </LabeledInput>
  ) : null;
};

const LoanForm = ({
  loan,
  personGid,
  formRef,
  disableEditing = false,
  isLoanPrimary,
  policy
}: {
  loan: ILoan;
  personGid: string;
  formRef: any;
  disableEditing?: boolean;
  isLoanPrimary: boolean;
  policy?: IMaticPolicy;
}) => {
  const queryClient = useQueryClient();
  const { data: verifiedLenders, isFetching } = useVerifiedLenders();
  const verifiedLendersOptions = buildVerifiedLendersOptions({ verifiedLenders });

  const currentCompany = servicerOrLender({ servicer: loan?.servicer, lender: loan?.lender });
  const companyOptions = buildCompanyOptions(verifiedLendersOptions, currentCompany);

  const { mutateAsync: updatePolicy } = useUpdatePolicy();

  return (
    <>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={{
          primary_loan: isLoanPrimary ? true : false,
          est_closing_date: loan.est_closing_date || '',
          loan_number: loan.loan_number || '',
          lender_gid: loan.lender?.gid || '',
          servicer_gid: loan.servicer?.gid || '',
          amount: loan.amount || '',
          payment_method: loan.payment_method || '',
          purpose: loan.purpose || '',

          officer_first_name: loan.officer?.first_name || '',
          officer_last_name: loan.officer?.last_name || '',
          officer_email: loan.officer?.email || '',
          officer_phone: phoneFormatter(loan.officer?.phone) || '',

          processor_first_name: loan.processor?.first_name || '',
          processor_last_name: loan.processor?.last_name || '',
          processor_email: loan.processor?.email || '',
          processor_phone: phoneFormatter(loan.processor?.phone) || ''
        }}
        validationSchema={yup.object().shape({
          est_closing_date: yup
            .date()
            .min(oldDate, "Date can't be earlier than 1990")
            .max(maxDate, "Date can't be later than 2100"),

          officer_email: emailField,
          officer_phone: phoneField({ loose: authInfo.features.loose_phone_validation }),

          processor_email: emailField,
          processor_phone: phoneField({ loose: authInfo.features.loose_phone_validation }),
          loan_number: yup.string().matches(/^[a-zA-Z0-9]{5,}$/, 'Should be at least 5 alphanumeric characters')
        })}
        validate={values => ({
          ...officerValidation(values),
          ...processorValidation(values)
        })}
        onSubmit={values => {
          if (policy) {
            const { id, ...rest } = policy;

            updatePolicy({
              person_gid: personGid,
              policy_id: id,
              carrier_id: policy.carrier.id,
              ...rest,
              primary_loan_gid: loan.gid
            } as unknown as UpdatePolicyRequest).then(() => {
              queryClient.invalidateQueries({ queryKey: [personGid, PERSON_MATIC_POLICIES_QUERY_KEY] });
            });
          }

          return updateLoan({
            personGid,
            loanGid: loan.gid,
            updateLoanRequest: {
              ...values,
              officer_gid: loan.officer?.gid,
              processor_gid: loan.processor?.gid
            } as UpdateLoanRequest
          }).then(() => queryClient.invalidateQueries({ queryKey: [PERSON_LOANS_QUERY_KEY, personGid] }));
        }}
      >
        {formikProps => {
          return (
            <Container mt={spacings.px12}>
              {policy && (
                <SelectField
                  label="Primary loan"
                  id="primary_loan"
                  name="primary_loan"
                  placeholder=""
                  options={[
                    { key: true, value: 'Yes' },
                    { key: false, value: 'No' }
                  ]}
                  ordered
                  inline
                  labelSize={LabelSize.Small}
                  disabled={isLoanPrimary}
                />
              )}
              {currentCompany?.type === CompanyType.Lender ? (
                <SelectField
                  label="Lender"
                  id="lender_gid"
                  name="lender_gid"
                  placeholder=""
                  options={companyOptions}
                  ordered
                  inline
                  labelSize={LabelSize.Small}
                  isLoading={isFetching}
                  disabled={!!loan.lender?.gid && disableEditing}
                />
              ) : (
                <SelectField
                  label="Lender"
                  id="servicer_gid"
                  name="servicer_gid"
                  placeholder=""
                  options={companyOptions}
                  ordered
                  inline
                  labelSize={LabelSize.Small}
                  isLoading={isFetching}
                  disabled={!!loan.servicer?.gid && disableEditing}
                />
              )}
              <SelectedVerifiedCompanyInfo
                verifiedLenders={verifiedLenders}
                servicerGid={formikProps.values.servicer_gid}
                lenderGid={formikProps.values.lender_gid}
              />
              <InputField
                label="Loan number"
                id="loan_number"
                name="loan_number"
                inline
                labelSize={LabelSize.Small}
                disabled={disableEditing}
              />
              <SelectField
                label="Purpose"
                id="purpose"
                name="purpose"
                placeholder=""
                options={Translations.loanPurposeOptions}
                inline
                labelSize={LabelSize.Small}
              />
              <SelectField
                label="Billing type"
                id="payment_method"
                name="payment_method"
                placeholder=""
                options={Translations.paymentMethodOptions}
                inline
                labelSize={LabelSize.Small}
              />
              <NumericField
                label="Amount"
                id="amount"
                name="amount"
                placeholder=""
                inline
                labelSize={LabelSize.Small}
                valueIsNumericString
                prefixIcon={<DollarIcon />}
              />
              <DateInputField
                label="Est closing"
                id="est_closing_date"
                name="est_closing_date"
                inline
                labelSize={LabelSize.Small}
              />
              <Text bold>Officer info</Text>
              <InputField
                label="First name"
                id="officer_first_name"
                name="officer_first_name"
                inline
                labelSize={LabelSize.Small}
              />
              <InputField
                label="Last name"
                id="officer_last_name"
                name="officer_last_name"
                inline
                labelSize={LabelSize.Small}
              />
              <InputField
                label="Email"
                id="officer_email"
                name="officer_email"
                type="email"
                inline
                labelSize={LabelSize.Small}
              />
              <PhoneField
                label="Phone"
                id="officer_phone"
                name="officer_phone"
                placeholder=""
                inline
                labelSize={LabelSize.Small}
              />
              <Text bold>Processor info</Text>
              <InputField
                label="First name"
                id="processor_first_name"
                name="processor_first_name"
                inline
                labelSize={LabelSize.Small}
              />
              <InputField
                label="Last name"
                id="processor_last_name"
                name="processor_last_name"
                inline
                labelSize={LabelSize.Small}
              />
              <InputField
                label="Email"
                id="processor_email"
                name="processor_email"
                type="email"
                inline
                labelSize={LabelSize.Small}
              />
              <PhoneField
                label="Phone"
                id="processor_phone"
                name="processor_phone"
                placeholder=""
                inline
                labelSize={LabelSize.Small}
              />
            </Container>
          );
        }}
      </Formik>
    </>
  );
};

export default LoanForm;
